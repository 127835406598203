import axios from "axios"
import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { SettingsContext } from "../../../context/SettingsContext"
import { API_URL } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const SettingsLoadView = () => {
  const { t } = useTranslation("data")
  const { settings, refetch, updateSetting } = useContext(SettingsContext)

  const runComputationTask = async () => {
    try {
      await axios.post(`${API_URL}/leadersLoad/compute`)

      refetch()
      toast.success("Задача запущена")
    } catch (error) {
      toast.error("Server error")
    }
  }

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">
          {t("settings.loadSettingsTitle")}
        </p>
      </div>

      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <p className="pt-3">
              <strong>{t("settings.lastRun")}: </strong>
              {dayjs(settings.lastLeadersLoadRun).format("DD/MM/YYYY HH:mm:ss")}
            </p>
          </div>

          <div className="column is-half has-text-right">
            <FormEntry>
              <button className="button is-link" onClick={runComputationTask}>
                {t("settings.leadersLoadCompute")}
              </button>
            </FormEntry>
          </div>
        </div>
      </div>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <div className="box">
            <p className="title is-5">
              {settings?.applyYear}/{settings?.applyYear - 1999}&nbsp;[apply]
            </p>

            {["B1", "B2", "B3", "B4", "M1", "M2", "Pr"].map(term => (
              <div className="field">
                <div className="control">
                  <label className="label">{term}</label>
                  <input
                    type="number"
                    className="input"
                    value={settings?.[`apply${term}Hours`]}
                    onChange={e =>
                      updateSetting(`apply${term}Hours`, e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="column is-half">
          <div className="box">
            <p className="title is-5">
              {settings?.studyYear}/{settings?.studyYear - 1999}&nbsp;[study]
            </p>

            {["B1", "B2", "B3", "B4", "M1", "M2", "Pr"].map(term => (
              <div className="field">
                <div className="control">
                  <label className="label">{term}</label>
                  <input
                    type="number"
                    className="input"
                    value={settings?.[`study${term}Hours`]}
                    onChange={e =>
                      updateSetting(`study${term}Hours`, e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsLoadView
