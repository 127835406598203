import { useTranslation } from "react-i18next"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import ProjectsContextProvider, {
  ProjectsContext,
} from "../../context/ProjectsContext"
import ProposalsContextProvider from "../../context/ProposalsContext"
import NavBar from "../helpers/NavBar"
import ProposalsPageView from "./projectLeader/ProposalsPageView"
import ProjectsPageView from "./projectLeader/ProjectsPageView"
import { useContext } from "react"
import { ApplicationsStudentContext } from "../../context/ApplicationsStudentContext"
import ApplicationListView from "./projectLeader/ApplicationListView"
import AllProjectsPageView from "./projectLeader/AllProjectsPageView"
import ProjectsFilterContextProvider from "../../context/ProjectsFilterContext"
import HomeView from "./HomeView"
import { UserContext } from "../../context/UserContext"
import TransferView from "./projectLeader/TransferView"
import { useTransferContext } from "../../context/TransferContext"
import GroupsPageView from "./projectLeader/GroupsPageView"
import BulletinPage from "../data/bulettin/BulletinPage"
import PracticeList from "../data/practice/PracticeList"

const ProjectLeaderWorkspace = () => {
  const { t } = useTranslation("ws")
  const {
    user: { role },
  } = useContext(UserContext)
  const { path, url } = useRouteMatch()
  const { projects } = useContext(ProjectsContext)
  const { applicationsLength, awaitingLength } = useContext(
    ApplicationsStudentContext
  )
  const { transfers } = useTransferContext()

  const awaitingTransfersCount = (transfers || []).filter(
    tr => tr.status === "awaiting"
  ).length

  return (
    <>
      <NavBar>
        <div class="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link">
            {t("menu.projectsLink")}
          </a>

          <div class="navbar-dropdown">
            <Link className="navbar-item" to={url + "/proposals"}>
              {t("menu.proposals")}
            </Link>

            <Link className="navbar-item" to={url + "/allProjects"}>
              {t("menu.allProjects")}
            </Link>

            {projects.length ? (
              <>
                <hr class="navbar-divider" />

                <Link className="navbar-item" to={url + "/projects"}>
                  {t("menu.projects")}
                </Link>

                <Link className="navbar-item" to={url + "/groups"}>
                  {t("menu.groups")}
                </Link>
              </>
            ) : null}

            <hr class="navbar-divider" />

            {applicationsLength ? (
              <Link className="navbar-item" to={url + "/applications"}>
                {t("menu.studentApps")}&nbsp;
                {awaitingLength > 0 ? (
                  <span className="tag is-rounded is-danger">{awaitingLength}</span>
                ) : null}
              </Link>
            ) : null}

            <Link className="navbar-item" to={url + "/transfers"}>
              {t("menu.transferRequests")}
              {awaitingTransfersCount <= 0 || (
                <>
                  &nbsp;
                  <span className="tag is-rounded is-danger">
                    {awaitingTransfersCount}
                  </span>
                </>
              )}
            </Link>
          </div>
        </div>

        <Link className="navbar-item" to={url + "/bulletin"}>
          {t("menu.bulletin")}
        </Link>

        <Link className="navbar-item" to={url + "/practice"}>
          {t("menu.practice")}
        </Link>
      </NavBar>

      <div className="section">
        <div className="container">
          <Switch>
            <Route exact path={path}>
              <HomeView />
            </Route>

            <Route path={path + "/projects"}>
              <ProjectsPageView />
            </Route>

            <Route path={path + "/groups"}>
              <GroupsPageView />
            </Route>

            <Route path={path + "/allProjects"}>
              <ProjectsContextProvider userType="student">
                <ProjectsFilterContextProvider>
                  <AllProjectsPageView />
                </ProjectsFilterContextProvider>
              </ProjectsContextProvider>
            </Route>

            <Route path={path + "/applications"}>
              <ApplicationListView />
            </Route>

            <Route path={path + "/transfers"}>
              <ProjectsContextProvider userType="student">
                <TransferView />
              </ProjectsContextProvider>
            </Route>

            <Route path={path + "/proposals"}>
              <ProposalsContextProvider userType="projectLeader">
                <ProposalsPageView />
              </ProposalsContextProvider>
            </Route>

            <Route path={path + "/bulletin"}>
              <BulletinPage />
            </Route>

            <Route path={path + "/practice"}>
              <PracticeList />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default ProjectLeaderWorkspace
